.multi-select,
.multi-list {
  ul {
    @apply divide-y divide-gray-200;
  }

  li {
    @apply p-4;
  }

  li > .multi-select-summary,
  li > .multi-list-summary {
    @apply flex items-center space-x-4;

    .multi-select-info,
    .multi-list-info {
      @apply flex-1 min-w-0;

      .multi-select-label,
      .multi-list-label {
        @apply text-sm font-medium text-gray-900 truncate;
      }

      .multi-select-detail {
        @apply text-sm text-gray-500 truncate;
      }
    }

    a.multi-select-remove,
    a.multi-list-remove {
      @apply inline-flex items-center shadow-sm px-2.5 py-0.5 border border-gray-300 text-sm leading-5 font-medium rounded-full text-gray-700 bg-white hover:bg-gray-50;
    }
  }

  .multi-select-appender,
  .multi-list-appender {
    @apply mx-4 sm:flex sm:items-center space-x-4;

    label {
      @apply text-sm flex-none;
    }

    select {
      @apply grow shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md overflow-clip max-w-full;
    }

    button {
      @apply mt-3 w-full flex-none items-center justify-center px-4 py-2 border border-transparent shadow-sm font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm;
    }
  }

  .multi-select-appender {
    @apply mt-10;
  }

  .multi-list-appender {
    @apply my-6;
  }

  .multi-select-empty,
  .multi-list-empty {
    @apply flex flex-row justify-center space-x-4 items-center;

    &.not-empty {
      @apply hidden;
    }

    svg {
      @apply h-8 w-8 text-gray-600;
    }

    p {
      @apply text-sm font-medium text-gray-700;
    }
  }

  .multi-select-option {
    @apply flex flex-col mr-20 ml-4;

    > div, > p {
      @apply flex-1;
    }
  }
}

.multi-select ul {
  @apply -mt-5;
}


