@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@import "./elements/form_steps";
@import "./elements/multi_select";

button.oauth-button, button.button {
  @apply inline-flex items-center px-6 py-3 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 no-underline;

  &.oauth-with-image {
    @apply py-2;
  }

  &:disabled {
    @apply bg-gray-600 hover:bg-gray-600;
  }

  img {
    @apply my-0;
  }

  &.hidden {
    display: none;
  }
}

.hint a {
  @apply underline text-cyan-600;
}

.flash a {
  @apply underline font-bold;
}

#day-rules {
  .from, .to {
    @apply w-32;
  }

  input[type=checkbox] {
    @apply focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 h-4 w-4 text-indigo-600 border-gray-300 rounded;
  }

  input[type=text], input[type=time] {
    @apply text-xs shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md;

    &.invalid {
      @apply border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500;
    }

    &[disabled] {
      @apply text-gray-300;
    }
  }
}

.faq-answer {
  p {
    @apply text-base text-gray-500 my-4;

    a {
      @apply text-indigo-600 underline;
    }
  }
}

.landing-calendars {
  @apply w-80 mx-6 relative;
  height: 200px;

  img {
    @apply absolute top-8;
  }

  #two {
    -webkit-animation-name: fade;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-duration: 8s;
    animation-name: fade;
    animation-iteration-count: infinite;
    animation-duration: 8s;
  }
}

@-webkit-keyframes fade {
  0% { opacity: 0; }
  33% { opacity: 0; }
  66% { opacity: 1; }
  100% { opacity: 1; }
}
@keyframes fade {
  0% { opacity: 0; }
  33% { opacity: 0; }
  66% { opacity: 1; }
  100% { opacity: 1; }
}

.visually-hidden {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width:1px !important;
}

.colour-list {
  .colour-check {
    @apply text-indigo-600 inset-y-0 right-0 flex items-center pr-4 hidden;

    svg {
      @apply h-5 w-5;
    }
  }

  ul {
    -webkit-overflow-scrolling: touch;
  }

  li.highlighted {
    @apply text-white bg-indigo-600;

    .colour-check {
      @apply text-white;
    }
  }

  li.selected {
    .colour-name {
      @apply font-semibold;
    }

    .colour-check {
      @apply absolute flex;
    }
  }
}

.autocomplete {
  @apply relative;

  .toggle {
    @apply absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none;

    svg {
      @apply h-5 w-5 text-gray-400;
    }
  }

  input {
    @apply shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md;
  }

  ul {
    @apply absolute z-10 mt-1 w-full max-w-3xl bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-y-auto focus:outline-none sm:text-sm;
    -webkit-overflow-scrolling: touch;
  }

  li {
    @apply text-gray-900 cursor-default select-none relative py-2 pl-8 pr-4 focus:outline-none;

    .autocomplete-option-label {
      @apply font-normal block truncate;
    }

    .autocomplete-option-check {
      @apply text-indigo-600 inset-y-0 left-0 items-center pl-1.5 hidden;

      svg {
        @apply h-5 w-5;
      }
    }
  }

  li.highlighted {
    @apply text-white bg-indigo-600;

    .autocomplete-option-check {
      @apply text-white;
    }
  }

  li.selected .autocomplete-option-check {
    @apply absolute flex;
  }
}

.notice {
  @apply border-l-4 p-4 mb-8;

  svg {
    @apply h-5 w-5;
  }

  p {
    @apply text-sm;
  }

  a {
    @apply underline;
  }
}

.notice-info {
  @apply bg-blue-50 border-blue-400;

  svg {
    @apply text-blue-400;
  }

  p {
    @apply text-blue-700;
  }
}

.notice-warning {
  @apply bg-red-50 border-red-400;

  svg {
    @apply text-red-600;
  }

  p {
    @apply text-red-700;
  }
}

.badge {
  @apply px-2 inline-flex text-xs leading-5 font-semibold rounded-full;

  &.badge-good {
    @apply bg-green-100 text-green-800;
  }

  &.badge-bad {
    @apply bg-red-100 text-red-800;
  }

  &.badge-neutral {
    @apply bg-gray-100 text-gray-800;
  }

  &.badge-transient {
    @apply bg-yellow-100 text-yellow-800
  }
}

.stacked_list {
  @apply bg-white shadow overflow-hidden sm:rounded-md;

  ul {
    @apply divide-y divide-gray-200;
  }

  .stacked_list_label {
    @apply text-sm font-medium text-indigo-600 truncate;
  }

  .stacked_list_badge {
    @apply ml-2 flex-shrink-0 flex;
  }

  .stacked_list_links {
    @apply mt-2 flex flex-row items-center text-sm text-gray-500 sm:mt-0;

    a {
      @apply mr-2 sm:mx-1 underline;
    }
  }

  .stacked_list_notes {
    @apply sm:flex;

    .stacked_list_note {
      @apply mt-2 flex items-center text-sm text-gray-500 sm:mt-0 sm:ml-6;

      &:first-child {
        @apply sm:ml-0;
      }

      svg {
        @apply flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400;
      }
    }
  }
}

.clipboard[data-clipboard-state="initial"] {
  .clipboard-initial {
    display: inline;
  }

  .clipboard-success {
    display: none;
  }
}

.clipboard[data-clipboard-state="success"] {
  .clipboard-initial {
    display: none;
  }

  .clipboard-success {
    display: inline;
  }
}

.workflow_event_summary {
  @apply ml-8;
}

div.hint {
  @apply px-4 text-sm w-full sm:col-span-6 text-gray-600;

  p {
    @apply leading-6 my-2;
  }

  ul {
    @apply list-disc pl-8 leading-6;
  }
}
