.form-step {
  .form-step-header {
    @apply p-4 flex flex-col border-l-4 border-indigo-600 hover:border-indigo-800 bg-indigo-100;

    .toggle-button {
      @apply text-left w-full flex justify-between items-start;
    }
  }

  .form-step-info {
    @apply flex-1 min-w-0 flex flex-col;

    h4 {
      @apply text-sm text-indigo-600 font-semibold tracking-wide uppercase group-hover:text-indigo-800;
    }

    span {
      @apply text-sm font-medium pt-2;
    }
  }

  .form-step-fields {
    @apply mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6;
  }

  .form-step-checked-fields {
    @apply mt-6 mx-4 space-y-5;
  }
}
